import React from "react";
import { useRef } from "react";
import useDate from "react-use-date";
import styled from "@emotion/styled";

const Demo = styled.div`
  margin-bottom: 20px;
`;

export const ClockBad = () => {
  // cheat a little here - just using `new Date()`
  // will cause it to update on each re-render,
  // which are triggered by the *other* timers on this page.
  // Force it to not update
  const date = useRef(new Date());

  return <Demo>The current time is: {date.current.toISOString()}</Demo>;
};

export const ClockGood = () => {
  const date = useDate();

  return <Demo>The current time is: {date.toISOString()}</Demo>;
};

export const SmallClock = ({ opts }) => {
  const date = useDate(opts);
  return <>{date.toISOString().slice(11, 23)}</>;
};
