import SEO from "../../../../src/components/seo";
import docker from "../../../../src/pages/arm-mac/docker.png";
import perf from "../../../../src/pages/arm-mac/perf.png";
import phantomjs from "../../../../src/pages/arm-mac/phantomjs.png";
import virtualbox from "../../../../src/pages/arm-mac/virtualbox.png";
import bootcamp from "../../../../src/pages/arm-mac/boot-camp.png";
import * as React from 'react';
export default {
  SEO,
  docker,
  perf,
  phantomjs,
  virtualbox,
  bootcamp,
  React
};