// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arm-mac-mdx": () => import("./../../../src/pages/arm-mac.mdx" /* webpackChunkName: "component---src-pages-arm-mac-mdx" */),
  "component---src-pages-auto-clicker-js": () => import("./../../../src/pages/auto-clicker.js" /* webpackChunkName: "component---src-pages-auto-clicker-js" */),
  "component---src-pages-back-is-forward-js": () => import("./../../../src/pages/back-is-forward.js" /* webpackChunkName: "component---src-pages-back-is-forward-js" */),
  "component---src-pages-css-grid-js": () => import("./../../../src/pages/css-grid.js" /* webpackChunkName: "component---src-pages-css-grid-js" */),
  "component---src-pages-highlight-js": () => import("./../../../src/pages/highlight.js" /* webpackChunkName: "component---src-pages-highlight-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-sandbox-mdx": () => import("./../../../src/pages/mdx-sandbox.mdx" /* webpackChunkName: "component---src-pages-mdx-sandbox-mdx" */),
  "component---src-pages-mobile-typing-speed-test-js": () => import("./../../../src/pages/mobile-typing-speed-test.js" /* webpackChunkName: "component---src-pages-mobile-typing-speed-test-js" */),
  "component---src-pages-react-use-date-mdx": () => import("./../../../src/pages/react-use-date.mdx" /* webpackChunkName: "component---src-pages-react-use-date-mdx" */),
  "component---src-pages-runaway-js": () => import("./../../../src/pages/runaway.js" /* webpackChunkName: "component---src-pages-runaway-js" */),
  "component---src-pages-sandbox-js": () => import("./../../../src/pages/sandbox.js" /* webpackChunkName: "component---src-pages-sandbox-js" */),
  "component---src-pages-stowaway-js": () => import("./../../../src/pages/stowaway.js" /* webpackChunkName: "component---src-pages-stowaway-js" */),
  "component---src-pages-tiny-link-js": () => import("./../../../src/pages/tiny-link.js" /* webpackChunkName: "component---src-pages-tiny-link-js" */),
  "component---src-pages-up-is-down-js": () => import("./../../../src/pages/up-is-down.js" /* webpackChunkName: "component---src-pages-up-is-down-js" */),
  "component---src-templates-all-pokemon-js": () => import("./../../../src/templates/all-pokemon.js" /* webpackChunkName: "component---src-templates-all-pokemon-js" */),
  "component---src-templates-github-readme-js": () => import("./../../../src/templates/github-readme.js" /* webpackChunkName: "component---src-templates-github-readme-js" */)
}

